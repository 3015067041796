/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Header from './header'
import FooterImg from '../components/footerImage'
import 'normalize.css'
import './layout.css'

const StyledMain = styled.main`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 20px;
  min-height: calc(100vh - 390px);
`
const StyledFooter = styled.footer`
  width: 100%;
  background-color: #2b4892;
  max-width: 100%;
  margin-top: 50px;
  margin: 0 auto;

  width: 100%;
  text-align: center;
`
const FooterLogoWrapper = styled.div`
  max-width: 300px;
  margin: 0 auto;
  padding: 20px 0;
  color: #ffffff;
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <StyledMain>{children}</StyledMain>
      <StyledFooter>
        <FooterLogoWrapper>
          © {new Date().getFullYear()}
          <FooterImg />
        </FooterLogoWrapper>
      </StyledFooter>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
