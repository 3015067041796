import { Link } from "gatsby"
import styled from "styled-components"
import PropTypes from "prop-types"
import React from "react"
import Image from "../components/image"

const StyledHeader = styled.header`
  width: 100%;
  margin: 40px 0;
  min-height: 150px;
`
const LogoContainer = styled.div`
  max-width: 300px;
  margin-bottom: 1rem;
  margin: 0 auto;
`

const StyledH1 = styled.h1`
  font-size: 3vh;
  text-align: center;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`

const Header = ({ siteTitle }) => (
  <StyledHeader>
    <div>
      <LogoContainer>
        <StyledLink to="/">
          <Image alt={siteTitle} />
          <StyledH1>Payment Portal</StyledH1>
        </StyledLink>
      </LogoContainer>
    </div>
  </StyledHeader>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
